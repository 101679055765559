/* eslint-disable indent */
import React from "react";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "./EmailTemplate.css";
import { useState, useEffect, useRef } from "react";
import { apis } from "../../services/Api";
import ReactQuill from "react-quill";
import Breadcrumbs from "../../common/Breadcrumbs";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";
import info from "../../assets/images/info.svg";

const EmailTemplate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [type, setType] = useState("Role Assigned To Myself");
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [messageforPopup, setMessageforPopup] = useState("");
  const [messageForFailure, setMessageForFailure] = useState("");
  const [emailTemplateData, setEmailTemplateData] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const crumbs = [
    { label: "Home", url: "/" },
    { label: "Email Template", url: "/emailtemplate" }
  ];
  const learningString = "{{LearnerName}}";
  const managerstring = "{{ManagerName}}";
  const rolestring = "{{RoleName}}";
  const liststring = "{{ListView}}";
  const lerneremaillstring = "{{LearnerEmail}}";
  const manageremailstring = "{{ManagerEmail}}";
  const divRef = useRef(null);

  const handleInforToggle =() =>{
    setShowDiv(!showDiv);
  }

  useEffect(() => {     const handleClickOutside = (event) => {    
    if (divRef.current && !divRef.current.contains(event.target)) {      
         setShowDiv(false);    
   }  
           };   
  document.addEventListener("click", handleClickOutside);     
   return () => {       document.removeEventListener("click", handleClickOutside);     };   }, []);
  const handleType = async (type) => {
    setType(type);
    let getEmailDataPerType ;
    emailTemplateData[0].forEach((item)=>{
      if(item.Type.trim().toLowerCase() == type.trim().toLowerCase()){
        getEmailDataPerType = item;
      }
    })
    if(getEmailDataPerType && getEmailDataPerType.Type.trim().toLowerCase() === type.trim().toLowerCase()){
      setSubject(getEmailDataPerType.Subject);
      setMessage(getEmailDataPerType.Message);
      setTitle(getEmailDataPerType?.Title ?? "");
    }else {
      setSubject("");
      setMessage("");
      setTitle("");
    }
  };

  //input box is there
  const handlesubjectChange = async (e) => {
    e.preventDefault();
    setSubject(e.target.value);
  };

  const handlTitleChange = async (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };
  //this is editor
  const handleMessageChange = async (message) => {
    setMessage(message);
  };

  const handleEmailTemplate = async () => {
    if ((type !== "" && subject !== "" && message !== "" && message !== "<p><br></p>")) {
        const res = await apis.updateEmailTemplate(type, subject, message, title);

      if (res) {
        setMessageForFailure("");
        setMessageforPopup("Email Template updated successfully.");
        const result = await apis.getEmailTemaplateData();
        let dataArray = Object.values(result["data"]);
        setEmailTemplateData(dataArray);
        setShowModal(true);
      } else {
        setMessageforPopup("")
        setMessageForFailure("Failed to update email template.");
        setShowModal(true);
      }
    } else {
      setMessageforPopup("");
      if( subject === "" && (message === "" || message === "<p><br></p>")) 
        setMessageForFailure("Please enter all details");
      else if(message === "" || message === "<p><br></p>") 
        setMessageForFailure("Please enter message"); 
    //  else if(title === "")
    //     setMessageForFailure("Please enter title"); 
     else
        setMessageForFailure("Please enter subject "); 
     
      setShowModal(true);
    }
  };

  useEffect(() =>{
      async function fetchMyAPI() {
      const result = await apis.getEmailTemaplateData();
      let dataArray = Object.values(result["data"]);
      setEmailTemplateData(dataArray);
    }
    fetchMyAPI()
  },[]);

  useEffect(() =>{
    let getEmailDataPerType;
    if(emailTemplateData !== undefined && emailTemplateData.length > 0){
      emailTemplateData[0].forEach((item)=>{
      if(item.Type.trim().toLowerCase() == type.trim().toLowerCase()){
        getEmailDataPerType = item;
      }
  });
    if(getEmailDataPerType) {
    setSubject(getEmailDataPerType.Subject);
    setTitle(getEmailDataPerType?.Title??"");
    setMessage(getEmailDataPerType.Message);
    }
  }
  },[emailTemplateData])

  useEffect(() => {
    setType(type);
  }, [type]);

  useEffect(() => {
    setSubject(subject);
  }, [subject]);

  useEffect(() => {
    setTitle(title);
  }, [title]);

  useEffect(() => {
    setMessage(message);
  }, [message]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline","link"],
      ["code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "link",
    "code-block",
    "list",
    "bullet"
  ];

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCancel = () => {
    navigate("/home");
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container fluid className="pl-30 py-4 emailtemplate">
        <div className="headerImage">
          <h1>{t("email-template")}</h1>
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={2} className="mt-3">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${type.trim().toLowerCase() === "Role Assigned To Myself".trim().toLowerCase() ? "active" : ""}`}
                    onClick={() => handleType("Role Assigned To Myself")}>
                    {t("role-assigned-to-myself")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${type.trim().toLowerCase() === "Skills Assigned To Myself".trim().toLowerCase() ? "active" : ""}`}
                    onClick={() => handleType("Skills Assigned To Myself")}>
                    {t("skills-assigned-to-myself")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${type.trim().toLowerCase() === "Submitted Skills To Approval".trim().toLowerCase() ? "active" : ""}`}
                    onClick={() => handleType("Submitted Skills To Approval")}>
                    {t("submitted-skills-to-approval")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${type.trim().toLowerCase() === "Roles Assigned To A Learner".trim().toLowerCase() ? "active" : ""}`}
                    onClick={() => handleType("Roles Assigned To A Learner")}>
                    {t("roles-assigned-to-a-learner")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${type.trim().toLowerCase() === "Skills Assigned To A Learner".trim().toLowerCase() ? "active" : ""}`}
                    onClick={() => handleType("Skills Assigned To A Learner")}>
                    {t("skills-assigned-to-a-learner")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${type.trim().toLowerCase() === "Skills Approved".trim().toLowerCase() ? "active" : ""}`}
                    onClick={() => handleType("Skills Approved")}>
                    {t("skills-approved")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${type.trim().toLowerCase() === "Skills Rejected".trim().toLowerCase() ? "active" : ""}`}
                    onClick={() => handleType("Skills Rejected")}>
                    {t("skills-rejected")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${type.trim().toLowerCase() === "Skills Assigned by Admin".trim().toLowerCase() ? "active" : ""}`}
                    onClick={() => handleType("Skills Assigned by Admin")}>
                    {t("skills-assigned-by-admin")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${type.trim().toLowerCase() === "Skills Approved by Admin".trim().toLowerCase() ? "active" : ""}`}
                    onClick={() => handleType("Skills Approved by Admin")}>
                    {t("skills-approved-by-admin")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10} className="mt-4">
              <div className="d-flex justify-content-end ">
                <Row>
                  <Col>
                    <button className="btn primary-btn" onClick={handleCancel}>
                      {t("cancel")}
                    </button>
                  </Col>
                  <Col>
                    <button className="btn primary-btn primary-blue" onClick={handleEmailTemplate}>
                      {t("update")}
                    </button>
                  </Col>
                </Row>
              </div>
              <div className="subject-line-container">
                <label htmlFor="subjectInput" className="mb-2">
                  {t("subject")}
                </label>
                <input
                  type="text"
                  id="subjectInput"
                  className="form-control subject-line-input"
                  placeholder="Enter subject"
                  value={subject}
                  onChange={handlesubjectChange}
                />
              </div>
               <div className="subject-line-container">
                <label htmlFor="titleInput" className="mb-2">
                  {t("title")}
                </label>
                <input
                  type="text"
                  id="titleInput"
                  className="form-control subject-line-input"
                  placeholder="Enter Title"
                  value={title}
                  onChange={handlTitleChange}
                />
              </div>
              <div className="message-box d-flex" ref = {divRef}>
              <label htmlFor="bodyInput" className="mb-2">
                  {t("body")}</label>
                <div className="info-text ms-auto">
                  <button onClick={handleInforToggle}> 
                  <img src={info} alt="info" ></img> <label className="mb-2 link-color">{("Click here to see the variables")}</label></button>
                  { showDiv &&
                    <div className="info-show-box">
                      <div className="variable-list">
                     <div className="variable-text">
                     <p><b>{learningString}</b></p>
                     </div>
                     <div className="variable-details">
                          <p>Will display the Learner(s) name in the email body </p>
                       </div>
                       </div>
                       <div className="variable-list">
                     <div className="variable-text">
                     <p><b>{managerstring}</b></p>
                     </div>
                     <div className="variable-details">
                          <p>Will display the managers name in the email body </p>
                          
                        </div>
                        </div>
                        <div className="variable-list">
                     <div className="variable-text">
                     <p><b>{rolestring}</b></p>
                     </div>
                     <div className="variable-details">
                        
                          <p>Will display the role name in the email body </p>
                      
                        </div>
                        </div>
                        <div className="variable-list">
                     <div className="variable-text">
                     <p><b>{liststring}</b></p>
                     </div>
                     <div className="variable-details">
                        
                          <p>Will display the list of skills approved/rejected/submitted in the email body </p>
                        
                        </div>
                        </div>
                        <div className="variable-list">
                     <div className="variable-text">
                     <p><b>{lerneremaillstring}</b></p>
                     </div>
                     <div className="variable-details">
                        
                          <p>Will display the Learner(s) email in the email body </p>
                        </div>
                        </div>
                        <div className="variable-list">
                     <div className="variable-text">
                     <p><b>{manageremailstring}</b></p>
                      </div>
                      <div className="variable-details">
                       
                          <p>Will display the managers email in the email body</p>
                        </div>
                        </div>
                      </div>
                  }
                 </div> 
               </div>
              
              {/* <div className="email-content-header"> */}
                <ReactQuill
                  value={message}
                  modules={modules}
                  formats={formats}
                  onChange={handleMessageChange}
                />
              {/* </div> */}
            </Col>
          </Row>
        </Tab.Container>
        <ModalforSuccessAndFailure
          messageforPopup={messageforPopup}
          messageForFailure={messageForFailure}
          show={showModal}
          onClose={handleCloseModal}
        />
      </Container>
    </>
  );
};

export default EmailTemplate;