import React from "react";
import { Table } from "react-bootstrap";
import Pagination from "./Pagination";
import SearchInput from "./SearchInput";
import { size } from "lodash";

function DataTable({
    handleInputChange,
    searchTerm,
    currentItems,
    currentPage,
    setCurrentPage,
    filteredData,
    itemsPerPage,
    children
}) {
    return (
        <>
            <SearchInput handleInputChange={handleInputChange} searchTerm={searchTerm} />

            {size(currentItems) > 0 ? (
                <>
                    <div className="p-4 pb-0 mb-4">
                        <Table responsive="lg" className="table">
                            {children}
                        </Table>
                    </div>

                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        filteredData={filteredData}
                        itemsPerPage={itemsPerPage}
                    />
                </>
            ) : (
                <h2 className="noData mb-3">No results found. Please try another search term.</h2>
            )}
        </>
    );
}

export default DataTable;
