module.exports = {
    base_url: "https://learningmanager.adobe.com/primeapi/v2",
    signin_url: "https://learningmanager.adobe.com",
    // front_end_app_url: "http://localhost:2000",
    client_id: "462c6e48-5684-4fa0-bb4e-dfdb7f6783ea",
    client_secret: "5ce7575c-6274-47ba-8123-974f444da275",
    // custom_app_url: "https://dxenablementhub.adobe.com", //"http://localhost:3001" "http://52.205.136.34:5003",
    account_id: 29997,
    application_url: "https://api.dxenablementhub.adobe.com/api",
    show_maintenance_view: false
};

// module.exports = {
//     base_url: "https://captivateprime.adobe.com",
//     app_back_end_url_token: "http://localhost:3001/v2/user/oauth/token",
//     app_back_end_url_refresh_token: "http://localhost:3001/v2/user/oauth/token/refresh",
//     front_end_app_url: "http://localhost:2000"//"https://harbingercaptivateprime.z13.web.core.windows.net"
// }
