import React from "react";
import { Modal } from "react-bootstrap";
import Failure_Icon from "../../assets/images/Failure_Icon.png";
import Success_Icon from "../../assets/images/Success_Icon.png";
import Failure1_Icon from "../../assets/images/Failure1_Icon.png";

function ModalforSuccessAndFailure({
    messageforPopup,
    show,
    onClose,
    warningPopup,
    messageForFailure
}) {
    return (
        <Modal centered show={show}>
            <Modal.Body>
                <div className="success-modal-container">
                    {messageforPopup && (
                        <div className="failure-modal-container-body my-5">
                            <img
                                className="failed-icon m-auto"
                                src={Success_Icon}
                                alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">{messageforPopup}</div>
                        </div>
                    )}
                    {messageForFailure && (
                        <div className="failure-modal-container-body my-5">
                            <img className="failed-icon" src={Failure1_Icon} alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">
                                {messageForFailure}
                            </div>
                        </div>
                    )}
                    {warningPopup && (
                        <div className="failure-modal-container-body my-5">
                            <img className="failed-icon" src={Failure_Icon} alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">{warningPopup}</div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn primary-btn m-2" onClick={onClose}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalforSuccessAndFailure;
