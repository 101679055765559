import { Image } from "react-bootstrap";
import up_icon from "../../assets/images/grey-up.svg";
import dark_up from "../../assets/images/dark_up.svg";
import dark_down from "../../assets/images/dark_down.svg";
import down_icon from "../../assets/images/grey-down.svg";

const TableHeading = ({ sortKey, sortOrder, columns, handleSort }) => {
    const renderSortingIcon = (columnKey) => {
        const upKey = columnKey === sortKey && sortOrder === "asc";
        const downKey = columnKey === sortKey && sortOrder !== "asc";

        return (
            columnKey !== "Action" && (
                <span className="sortIcons mx-1">
                    <Image
                        src={upKey ? dark_up : up_icon}
                        className={`cpb-1-up ${upKey ? "active" : ""}`}
                        alt="Up"
                        title="Up"
                        height={11}
                        width={11}
                        onClick={() => handleSort(columnKey)}
                    />
                    <Image
                        src={downKey ? dark_down : down_icon}
                        className={`cpb-1 ${downKey ? "active" : ""}`}
                        alt="Down"
                        title="Down"
                        height={11}
                        width={11}
                        onClick={() => handleSort(columnKey)}
                    />
                </span>
            )
        );
    };

    return (
        <thead>
            <tr className="tableRows headerRows">
                {columns.map(({ id, title, property }) => {
                    return (
                        <th key={id} className="columnHeaders">
                            {title}
                            {renderSortingIcon(property)}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default TableHeading;
