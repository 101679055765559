/* eslint-disable */
import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import user_icon from "../../../assets/images/user_importcsv.png";
import success_icon from "../../../assets/images/success_importcsv.png";
import failed_icon from "../../../assets/images/failed_importcsv.png";
import calendar_icon from "../../../assets/images/calendar_importcsv.png";
import "../Importcsv.css";
import moment from "moment";
import info from "../../../assets/images/info.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const ImportCSVCardUserDetail = ({
    lastUploadDate,
    userName,
    statusOfLastImport,
    importStatus,
    showFileUploadStatus,
    csvFileName,
    error,
    id,
    showInfoModalOpen
}) => {
    const [errorStatus, setErrorStatus] = useState(error);
    const [rowNumber, setRowNumber] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (errorStatus !== true) {
            setErrorStatus(error);
        }
    }, [error]);

    const handleInforToggle=(element)=>{
    let rowNumber=[];
    let msg = "";
    let apiError=false;
    statusOfLastImport?.forEach(ele => {
        if(ele?.ImportType === element) {
            if(ele?.responseMessage?.msg) {
                msg= ele?.responseMessage?.msg;
                msg= msg.replace(/:- /g, "<li>");
                setErrorMsg(msg);
            } else if (Array.isArray(ele?.responseMessage?.error)) {
                apiError=false;
                ele?.responseMessage?.error.forEach(arr=>{
                    if(arr?.rowNumber) {
                        rowNumber?.push(arr?.rowNumber);
                    } else if(arr?.rowNum) {
                        rowNumber?.push(arr?.rowNum);
                    }
                })
            } else if(ele?.responseMessage?.error === "NONE_PROCESSED" || ele?.responseMessage?.error === "ABSENT_SKILLS") {
                apiError=true;
                let data=ele?.responseMessage?.msg;
                data= data?.replace(/\,/g, "<li>");
                data= data.replace(/:- /g, "<li>");
                rowNumber?.push(data)
            } else if(ele?.responseMessage) {
                //msg= ele?.responseMessage.replace(/\,/g, "<li>");
                msg= ele?.responseMessage;
                msg= msg.replace(/:- /g, "<li>");
                setErrorMsg(msg);
            }
        }
    });
    if(rowNumber.length >0){
        let Text="";
        if(element === "masterSkillsCsvUploads") {
            Text= `CSV import failed due to error(s) in following row(s): ${rowNumber?.join(', ')}`;
        } else{
            Text= `Following row(s) could not be inserted due to error: ${rowNumber?.join(', ')}`;
        }
        if(apiError)
            showInfoModalOpen(rowNumber)
        else
            showInfoModalOpen(Text)
    } else {
        showInfoModalOpen(msg)
    }
    setRowNumber(rowNumber);
}
    

    return (
        <>
            <div>
                <span className="me-2">
                    <img src={calendar_icon} alt=""></img>
                </span>
                {lastUploadDate ? (
    lastUploadDate
        .filter((element) => {
            return element ? element.ImportType === id : false;
        })
        .map((e) => {
            const uploadedDate = moment(e.UploadedOn);
            if (uploadedDate.isValid()) {
                return (
                    <span className="right-bar pe-3 me-3">
                        {uploadedDate.format("MMM Do YYYY")}
                    </span>
                );
            } else {
                return (
                    <span className="right-bar pe-3 me-3">Invalid Date</span>
                );
            }
        })
) : (
    <span className="right-bar pe-3 me-3">No Date</span>
)}

                <span className="me-2">
                    <img src={user_icon} alt=""></img>
                </span>
                {userName ? (
                    userName
                        .filter((element) => {
                            return element ? element.ImportType === id : false;
                        })
                        .map((e) => <span className="right-bar pe-3 me-3">{e.UploadedBy}</span>)
                ) : (
                    <span className="right-bar pe-3 me-3">No Date</span>
                )}

                <span className="statusText me-2">Status of Last Import: </span>
                {statusOfLastImport ? (
                    statusOfLastImport
                        .filter((element) => {
                            return element ? element.ImportType === id : false;
                        })
                        .map((e) => (
                            <span className="statusText me-2">
                                {e.status.toLowerCase().includes("passed") ||
                                e.status.toLowerCase().includes("success")
                                    ? "Success"
                                    : e.status.toLowerCase().includes("started")
                                    ? "In Progress"
                                    : "Failed"}
                            </span>
                        ))
                ) : (
                    <span className="statusText me-2">No Data</span>
                )}
   {
     (id === "masterSkillsCsvUploads" || id === "usrEnrlmntCsvUploads") &&
                <button onClick={(e)=>handleInforToggle(id)} className="button-tooltip">
                    <img src={info} alt="info" />
                </button>
    }
            </div>
        </>
    );
};

export default ImportCSVCardUserDetail;
