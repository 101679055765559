/* eslint-disable linebreak-style */
import axios from "axios";
import { application_url, base_url, client_id, client_secret, signin_url } from "../AppConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";

async function getToken(code) {
    const params = new URLSearchParams({
        client_id: client_id,
        client_secret: client_secret,
        code
    });
    const response = await axios.post(`${signin_url}/oauth/token`, params, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
    });
    return response.data;
}

async function checkToken() {
    const response = await axios.get(`${base_url}/user`);
    const result = response.data;
    return result;
}

async function refreshToken(refresh_token) {
    const params = new URLSearchParams({
        client_id: client_id,
        client_secret: client_secret,
        refresh_token
    });
    const response = await axios.post(`${base_url}/oauth/token/refresh`, params, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
    });
    const result = response.data;
    return result;
}

async function getEnrollments(cursor, limit = 100) {
    let url = `${base_url}/enrollments?page[limit]=${limit}&filter.loTypes=course,learningProgram,certification,jobAid&sort=-dateEnrolled`;
    if (cursor) {
        url = url + `&page[cursor]=${cursor}`;
    }
    const response = await axios.get(url);
    return response.data;
}

async function getMyLearningByDueDate(cursor,sort, limit = 10) {
    let url = `${base_url}/learningObjects?page[limit]=${limit}&include=enrollment.loInstance,skills.skillLevel.skill,instances.enrollment&filter.loTypes=course,learningProgram,certification,jobAid&sort=${sort}&filter.ignoreEnhancedLP=false&filter.learnerState=enrolled,started&language=en`;
    if (cursor) {
        url = url + `&page[cursor]=${cursor}`;
    }
    const response = await axios.get(url);
    return response.data;
}

async function getLearningObjects(cursor, limit = 10, params, myLearning, selectedFilters) {
    let url = `${base_url}/learningObjects?include=enrollment.loInstance,instances&page[limit]=${limit}&filter.ignoreEnhancedLP=false&language=en`;

    if (selectedFilters["filter.loTypes"]?.length === 0) {
        url = `${url}&filter.loTypes=course%2ClearningProgram%2Ccertification%2CjobAid%2Ccertification`;
    }
    if (
        !myLearning &&
        selectedFilters.sort.length > 0 &&
        selectedFilters["filter.learnerState"]?.length === 0
    ) {
        url = `${url}&filter.learnerState=enrolled%2Ccompleted%2Cstarted%2Cnotenrolled`;
    }
    if (myLearning && selectedFilters["filter.learnerState"]?.length === 0) {
        // If MyLearning is true and filter.learnerState is empty, include "enrolled", "completed", "started" learner states
        url = `${url}&filter.learnerState=enrolled%2Ccompleted%2Cstarted`;
    }
    if (cursor) {
        url = url + `&page[cursor]=${cursor}`;
    }
    if (params) {
        url = url + `&${params}`;
    }
    const response = await axios.get(url);
    return response.data;
}

async function getLearningCoursesbySkillName(skillName, cursor, limit = 10,loTypes="course%2ClearningProgram%2CjobAid%2Ccertification") {
    let url = `${base_url}/learningObjects?include=enrollment%2Cskills.skillLevel.skill.levels%2C&page[limit]=${limit}&filter.ignoreEnhancedLP=false&language=en&filter.loTypes=${loTypes}&filter.skillName=${encodeURIComponent(skillName)}`;

    if (cursor) {
        url = url + `&page[cursor]=${cursor}`;
    }

    const response = await axios.get(url);
    return response;
}

async function searchCourses(searchStr, cursor, limit = 10, params) {
    let url = `${base_url}/search?include=model.enrollment%2Cmodel.enrollment.loInstance&page[limit]=${limit}&autoCompleteMode=true&filter.ignoreEnhancedLP=false&language=en&matchType=phrase_and_match&persistSearchHistory=true&stemmed=true&highlightResults=true`;

    if (!params.includes("filter.loTypes")) {
        url = url + "&filter.loTypes=course%2ClearningProgram%2Ccertification%2CjobAid";
    }

    if (!params.includes("filter.learnerState")) {
        url = url + "&filter.learnerState=enrolled%2Ccompleted%2Cstarted%2Cnotenrolled";
    }

    if (!params.includes("filter.loFormat")) {
        url =
            url +
            "&filter.loFormat=Activity%2CBlended%2CClassroom%2CSelf%20Paced%2CVirtual%20Classroom";
    }

    if (!params.includes("sort")) {
        url = url + "&sort=relevance";
    }

    if (cursor) {
        url = url + `&page[cursor]=${cursor}`;
    }
    if (searchStr) {
        url = url + `&query=${searchStr}`;
    }
    if (params) {
        //url = url + `&${params},discussion`;
        url = url + `&${params}`;
    }
    const response = await axios.get(url);
    return response.data;
}

//Retrieve L1Feedback form information for the specified learningObjectInstance
async function removeRheLearningObjectFromTheBookmarks(loId) {
    const response = await axios.delete(`${base_url}/primeapi/v2/learningObjects/${loId}/bookmark`);
    return response;
}

//Retrieve L1Feedback form information for the specified learningObjectInstance
async function addTheLearningObjectToTheBookmarks(loId) {
    const response = await axios.post(`${base_url}/learningObjects/${loId}/bookmark`);
    return response;
}

//Retrieve L1Feedback form information for the specified learningObjectInstance
async function retrieveL1FeedbackFormInformation(loId, loInstanceId) {
    const response = await axios.get(
        `${base_url}/learningObjects/${loId}/instances/${loInstanceId}/l1Feedback`
    );
    const result = response.data;
    return result;
}

//Send L1 feedback answers from a learner
async function sendL1FeedbackAnswersFromALearner(enrollmentId, data) {
    // eslint-disable-next-line no-useless-catch
    try {
        const response = await axios.post(
            `${base_url}/enrollments/${enrollmentId}/l1Feedback`,
            data,
            {
                headers: {
                    "Content-Type": "application/vnd.api+json;charset=UTF-8",
                    Accept: "application/vnd.api+json;charset=UTF-8"
                }
            }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

async function getLearningObject(loId) {
    const response = await axios.get(
        // `${base_url}/learningObjects/${loId}?include=enrollment.loResourceGrades%2Cinstances.loResources.resources%2Cskills.skillLevel.skill%2CsubLOs.instances.loResources.resources%2CprerequisiteLOs`
        `${base_url}/learningObjects/${loId}?include=enrollment.loResourceGrades%2CsubLOs.enrollment.loResourceGrades%2Cenrollment.loInstance.loResources.resources%2Cinstances.loResources.resources%2Cskills.skillLevel.skill%2CsubLOs.instances.subLoInstances%2CsupplementaryLOs.instances.loResources.resources%2csubLOs.instances.loResources.resources%2CprerequisiteLOs%2CsupplementaryResources%2CsubLOs.supplementaryResources`
    );
    const result = response?.data;
    return result;
}

async function getLearningObjectsByIds(ids) {
    const response = await axios.get(
        `${base_url}/learningObjects?include=enrollment,skills.skillLevel.skill&page[limit]=10&sort=name&ids=${ids.join(
            "%2C"
        )}&filter.ignoreEnhancedLP=false&language=en`
    );
    const result = response.data;
    return result;
}

async function getCatalogs(offset = 0, limit = 100) {
    const response = await axios.get(
        `${base_url}/catalogs?page[offset]=${offset}&page[limit]=${limit}&sort=dateCreated`
    );
    const result = response?.data;
    return result;
}

async function getLearningObjectsByCatlogID(id, cursor = "", limit = 100) {
    let url = `${base_url}/learningObjects?page[limit]=${limit}&filter.loTypes=course%2ClearningProgram%2CjobAid%2Ccertification&filter.catalogIds=${id}&sort=name&filter.ignoreEnhancedLP=false&language=en`;
    if (cursor) {
        url = url + `&page[cursor]=${cursor}`;
    }
    const response = await axios.get(url);
    const result = response?.data;
    return result;
}
async function getLevel(id, offset = 0, limit = 10) {
    const response = await axios.get(
        `${base_url}/skills?include=levels&page[offset]=${offset}&page[limit]=${limit}&sort=name&ids=${id}`
    );
    const result = response?.data;
    return result;
}

async function setEnrollment(loId, loInstanceId) {
    const response = await axios.post(
        `${base_url}/enrollments?loId=${loId}&loInstanceId=${loInstanceId}`
    );
    const result = response.data;
    return result;
}

async function UpdateRrating(loInstanceId, rating) {
    const response = await axios.patch(`${base_url}/enrollments/${loInstanceId}/rate`, {
        rating
    });
    const result = response;
    return result;
}

//Unenroll from a learning object instance
async function unEnroll(enrollmentId) {
    const token = localStorage.getItem("access_token");
    const response = await axios.delete(`${base_url}/enrollments/${enrollmentId}`, {
        headers: {
            Accept: "application/vnd.api+json",
            Authorization: "oauth " + token
        }
    });
    const result = response.data;
    return result;
}

async function getRecommendations(cursor, limit = 10) {
    let url = `${base_url}/recommendations?include=learningObject&page[limit]=${limit}&filter.recType=peer_group&filter.loTypes=course%2ClearningProgram%2CjobAid%2Ccertification&filter.ignoreEnhancedLP=false`;
    if (cursor) {
        url = url + `&page[cursor]=${cursor}`;
    }
    const response = await axios.get(url);
    const result = response.data;
    return result;
}

async function getEnrollmentCount(learningObjectId, learningObjectInstanceId) {
    if (!learningObjectId || !learningObjectInstanceId) {
        return {};
    }
    const response = await axios.get(
        `${base_url}/learningObjects/${learningObjectId}/instances/${learningObjectInstanceId}/summary`
    );
    const result = response.data;
    return result;
}

//Retrieves the list of users for an account
// async function getUsersForAdminSide(page = 1, limit = 10) {
//     let url = `${custom_app_url}/v1/admin/users?page=${page}&limit=${limit}`;
//     const response = await axios.get(url);
//     const result = response.data;
//     return result;
// }

//Retrieves the list of users for an account
async function getUsers(cursor, offset = 0, limit = 10, filter, sort = "id", ids) {
    let url = `${base_url}/primeapi/v2/users?page[offset]=${offset}&page[limit]=${limit}&sort=${sort}`;
    if (cursor) {
        url = url + `&page[cursor]=${cursor}`;
    }
    if (filter) {
        url = url + `&filter=${filter}`;
    }
    if (ids) {
        url = url + `&ids=${ids}`;
    }
    const response = await axios.get(url);
    const result = response.data;
    return result;
}

async function getUser() {
    const response = await axios.get(`${base_url}/user`);
    const result = response.data;
    return result;
}

async function getUserGroups(offset = 0, limit = 10) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/users/15197137/userGroups?page[offset]=${offset}&page[limit]=${limit}&sort=name`
    );
    const result = response.data;
    return result;
}

async function updateUser(userId, body) {
    const response = await axios.patch(`${base_url}/users/${userId}`, body);
    const result = response.data;
    return result;
}

async function getAccount() {
    const response = await axios.get(`${base_url}/primeapi/v2/account`);
    return response.data;
}

async function getUserSkills(userId, offset = 0, limit = 10) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/users/${userId}/userSkills?include=skill&page[offset]=${offset}&page[limit]=${limit}&sort=dateAchieved`
    );
    const result = response.data;
    return result;
}

async function getUserSkillInterests(userId, offset = 0, limit = 10) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/users/${userId}/skillInterests?include=skill&page[offset]=${offset}&page[limit]=${limit}&sort=dateCreated&filter.skillInterestTypes=ADMIN_DEFINED`
    );
    const result = response.data;
    return result;
}

async function addUserSkillInterests(userId, skillId) {
    const response = await axios.post(
        `${base_url}/primeapi/v2/users/${userId}/skillInterest/${skillId}?filter.skillInterestTypes=ADMIN_DEFINED`
    );
    const result = response.data;
    return result;
}

async function deleteUserSkillInterests(userId, skillId) {
    const response = await axios.delete(
        `${base_url}/primeapi/v2/users/${userId}/skillInterest/${skillId}?filter.skillInterestTypes=ADMIN_DEFINED`
    );
    const result = response.data;
    return result;
}

async function getUserNotifications(userId, limit = 10) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/users/${userId}/userNotifications?page[limit]=${limit}&announcementsOnly=false&language=en_US`
    );
    const result = response.data;
    return result;
}

async function patchUserNotifications(userId, body) {
    body.attributes.actionTaken = true;
    body.attributes.read = true;
    await axios.patch(
        `${base_url}/primeapi/v2/users/${userId}/userNotifications/${body.id}`,
        {
            data: body
        },
        {
            headers: {
                "Content-Type": "application/vnd.api+json;charset=UTF-8"
            }
        }
    );
}

//Get a list of all the skills for an account in your organization. It includes the skill name and description of each skill
async function getSkills(offset = 0, limit = 100) {
    const response = await axios.get(
        `${base_url}/skills?page[offset]=${offset}&page[limit]=${limit}&sort=name`
    );
    const result = response.data;
    return result;
}

async function getBadgesForOrganization(offset = 0, limit = 10) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/badges?page[offset]=${offset}&page[limit]=${limit}&sort=name`
    );
    const result = response.data;
    return result;
}

async function getBadges(userId, offset = 0, limit = 10) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/users/${userId}/userBadges?include=badge&page[offset]=${offset}&page[limit]=${limit}&sort=dateAchieved`
    );
    const result = response.data;
    return result;
}

async function getBadge(badgeId) {
    const response = await axios.get(`${base_url}/primeapi/v2/badges/${badgeId}`);
    const result = response.data;
    return result;
}

//Retrieves the details of a user badge
async function RetrievesTheDetailsOfAUserBadge(userId, userBadgeId) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/users/${userId}/userBadges/${userBadgeId}?include=model.instances`
    );
    const result = response.data;
    return result;
}

//Retrieves tags
async function RetrievesTags() {
    const response = await axios.get(`${base_url}/primeapi/v2/data?filter.tagName=true`);
    const result = response.data;
    return result;
}

//Retrieves skill Names
async function RetrievesSkillName() {
    const response = await axios.get(`${base_url}/primeapi/v2/data?filter.skillName=true`);
    const result = response.data;
    return result;
}

//Get social profile of the user
async function getSocialProfileOfTheUser(id) {
    const response = await axios.get(`${base_url}/primeapi/v2/socialProfiles/${id}`);
    const result = response.data;
    return result;
}

//Follow a user
async function followAUser(id) {
    const response = await axios.post(`${base_url}/primeapi/v2/socialProfiles/${id}/followers`);
    const result = response.data;
    return result;
}

//Unfollow a user
async function unFollowAUser(id) {
    const response = await axios.delete(`${base_url}/primeapi/v2/socialProfiles/${id}/followers`);
    const result = response.data;
    return result;
}

//Get all profiles whom mentioned user follows
async function getFollowers(userId, offset = 0, limit = 10) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/socialProfiles/${userId}/followers?page[offset]=${offset}&page[limit]=${limit}&sort=dateCreated`
    );
    const result = response.data;
    return result;
}

//Get all profiles who follows mentioned user
async function getFollowed(userId, offset = 0, limit = 10) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/socialProfiles/${userId}/followed?page[offset]=${offset}&page[limit]=${limit}&sort=dateCreated`
    );
    const result = response.data;
    return result;
}

//Send L1 feedback answers from a learner
// async function sumbitLoFeedback(id) {
//     const response = await axios.delete(`${base_url}/primeapi/v2/socialProfiles/${id}/followers`);
//     const result = response.data;
//     return result;
// }

//! social profile

//Create a board in the account
async function createBoard(data) {
    const response = await axios.post(`${base_url}/primeapi/v2/boards`, data);
    const result = response.data;
    return result;
}

//Delete a board
async function deleteBoard(boardId) {
    const response = await axios.delete(`${base_url}/primeapi/v2/boards/${boardId}`);
    const result = response.data;
    return result;
}

//Retrieves a list of boards for an account
async function getBoards(offset = 0, limit = 10, sort = "name", filter) {
    let url = `${base_url}/primeapi/v2/boards?page[offset]=${offset}&page[limit]=${limit}&sort=${sort}`;
    if (filter) {
        url = `${url}&filter.boardType=${filter}`;
    }
    const response = await axios.get(url);
    const result = response.data;
    return result;
}

//Retrieves the details of a board
async function retrievesTheDetailsOfABoard(id) {
    let url = `${base_url}/primeapi/v2/boards/${id}?include=skills%2CcreatedBy`;
    const response = await axios.get(url);
    const result = response.data;
    return result;
}

//Get moderators for the given board
async function getModerators(boardId, offset = 0, limit = 10) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/boards/${boardId}/moderators?page[offset]=${offset}&page[limit]=${limit}`
    );
    const result = response.data;
    return result;
}

//Add moderators to the board
async function addModerators(boardId, data) {
    const response = await axios.post(`${base_url}/primeapi/v2/boards/${boardId}/moderators`, data);
    const result = response.data;
    return result;
}

//Report abuse for a board
async function reportAbuseForABoard(boardId) {
    const response = await axios.post(`${base_url}/primeapi/v2/boards/${boardId}/reportAbuse`);
    const result = response.data;
    return result;
}

//Get Board users
async function getBoardUsers(boardId, offset = 0, limit = 10) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/boards/${boardId}/users?page[offset]=${offset}&page[limit]=${limit}`
    );
    const result = response.data;
    return result;
}

//Add users to the private board
async function addUsersToThePrivateBoard(boardId, data) {
    const response = await axios.post(`${base_url}/primeapi/v2/boards/${boardId}/users`, data);
    const result = response.data;
    return result;
}

//Favorite a board
async function setFavoriteABoard(boardId) {
    const response = await axios.post(`${base_url}/primeapi/v2/boards/${boardId}/favorite`);
    const result = response.data;
    return result;
}

//Delete Favorite a board
async function removeFavoriteABoard(boardId) {
    const response = await axios.delete(`${base_url}/primeapi/v2/boards/${boardId}/favorite`);
    const result = response.data;
    return result;
}

//Retrieves a list of posts for a given board
async function getPostsForAGivenBoard(boardId, offset = 0, limit = 10, sort = "-dateCreated") {
    const response = await axios.get(
        `${base_url}/primeapi/v2/boards/${boardId}/posts?page[offset]=${offset}&page[limit]=${limit}&sort=${sort}`
    );
    const result = response.data;
    return result;
}

//Add a post in the board specified by the board id
async function addAPostInTheBoard(boardId, data) {
    const response = await axios.post(`${base_url}/primeapi/v2/boards/${boardId}/posts`, data);
    const result = response.data;
    return result;
}

//Increment count of views on the board
async function IncrementCountOfViewsOnTheBoard(id) {
    const response = await axios.post(`${base_url}/primeapi/v2/boards/${id}/views`);
    const result = response.data;
    return result;
}

//Get Posts in the account
async function getPostsInTheAccount(offset = 0, limit = 10, sort = "-dateCreated") {
    const response = await axios.get(
        `${base_url}/primeapi/v2/posts?page[offset]=${offset}&page[limit]=${limit}&sort=${sort}&filter.board.state=ACTIVE&filter.state=ACTIVE`
    );
    const result = response.data;
    return result;
}

//Delete a post
async function deleteAPost(postId) {
    const response = await axios.delete(`${base_url}/primeapi/v2/posts/${postId}`);
    const result = response.data;
    return result;
}

//Update the created post
async function updateTheCreatedPost(postId, data) {
    const response = await axios.patch(`${base_url}/primeapi/v2/posts/${postId}`, data);
    const result = response.data;
    return result;
}

//Update option selected for the given poll post

async function updateOptionSelectedForTheGivenPollPost(postId, optionId) {
    const response = await axios.post(
        `${base_url}/primeapi/v2/posts/${postId}/pollvote?optionId=${optionId}`
    );
    const result = response.data;
    return result;
}

//Report abuse for a post
async function reportAbuseForAPost(postId, data) {
    // eslint-disable-next-line no-useless-catch
    try {
        const response = await axios.post(
            `${base_url}/primeapi/v2/posts/${postId}/reportAbuse`,
            data
        );
        const result = response.data;
        return result;
    } catch (error) {
        throw error;
    }
}

//Vote to the post
async function voteToThePost(postId, action = "UP") {
    const response = await axios.post(
        `${base_url}/primeapi/v2/posts/${postId}/vote?action=${action}`
    );
    const result = response.data;
    return result;
}

//Delete a vote in the post
async function deleteAVoteInThePost(postId, action = "UP") {
    const response = await axios.delete(
        `${base_url}/primeapi/v2/posts/${postId}/vote?action=${action}`
    );
    const result = response.data;
    return result;
}

//Retrieves a list of comments for a given post
async function retrievesAListOfCommentsForAGivenPost(
    postId,
    offset = 0,
    limit = 10,
    sort = "-dateCreated"
) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/posts/${postId}/comments?page[offset]=${offset}&page[limit]=${limit}&sort=${sort}&include=createdBy&filter.state=ACTIVE`
    );
    const result = response.data;
    return result;
}

//Add comment in the post specified by the post id
async function addCommentInThePostSpecifiedByThePostId(postId, data) {
    const response = await axios.post(`${base_url}/primeapi/v2/posts/${postId}/comments`, data);
    const result = response.data;
    return result;
}

//Delete a comment
async function deleteAComment(commentId) {
    const response = await axios.delete(`${base_url}/primeapi/v2/comments/${commentId}`);
    const result = response.data;
    return result;
}

//Retrieves the details of a comment
async function getComment(commentId) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/comments/${commentId}?&include=createdBy`
    );
    const result = response.data;
    return result;
}

//Vote the given comment
async function voteTheGivenComment(commentId, action = "UP") {
    const response = await axios.post(
        `${base_url}/primeapi/v2/comments/${commentId}/vote?action=${action}`
    );
    const result = response;
    return result;
}

//Delete the vote made on the comment
async function deleteTheVoteMadeOnTheComment(commentId, action = "UP") {
    const response = await axios.delete(
        `${base_url}/primeapi/v2/comments/${commentId}/vote?action=${action}`
    );
    const result = response.data;
    return result;
}

//Update a comment
async function updateAComment(commentId, data) {
    const response = await axios.patch(`${base_url}/primeapi/v2/comments/${commentId}`, data);
    const result = response.data;
    return result;
}

//Report abuse on the given comment
async function reportAbuseOnTheGivenComment(commentId, data) {
    const response = await axios.post(
        `${base_url}/primeapi/v2/comments/${commentId}/reportAbuse`,
        data
    );
    const result = response.data;
    return result;
}

//Add Reply to the comment
async function addReplyToTheComment(commentId, data) {
    const response = await axios.post(
        `${base_url}/primeapi/v2/comments/${commentId}/replies`,
        data
    );
    const result = response.data;
    return result;
}

//Get replies for a comment
async function getRepliesForAComment(commentId, offset = 0, limit = 10, sort = "-dateUpdated") {
    const response = await axios.get(
        `${base_url}/primeapi/v2/comments/${commentId}/replies?page[offset]=${offset}&page[limit]=${limit}&sort=${sort}&filter.state=ACTIVE`
    );
    const result = response.data;
    return result;
}

//Get reply object specified by reply id
async function getReply(replyId) {
    const response = await axios.get(
        `${base_url}/primeapi/v2/replies/${replyId}?&include=createdBy`
    );
    const result = response.data;
    return result;
}

//Update a reply
async function updateAReply(replyId, data) {
    const response = await axios.patch(`${base_url}/primeapi/v2/replies/${replyId}`, data);
    const result = response.data;
    return result;
}

//report abuse on the Reply
async function reportAbuseOnTheReply(replyId, data) {
    const response = await axios.post(
        `${base_url}/primeapi/v2/replies/${replyId}/reportAbuse`,
        data
    );
    const result = response.data;
    return result;
}

//Vote to the Reply
async function voteToTheReply(replyId, action = "UP") {
    const response = await axios.post(
        `${base_url}/primeapi/v2/replies/${replyId}/vote?action=${action}`
    );
    const result = response.data;
    return result;
}

//Delete a vote on the reply
async function deleteAVoteOnTheReply(replyId, action = "UP") {
    const response = await axios.delete(
        `${base_url}/primeapi/v2/replies/${replyId}/vote?action=${action}`
    );
    const result = response.data;
    return result;
}

//Delete a reply
async function DeleteAReply(replyId) {
    const response = await axios.delete(`${base_url}/primeapi/v2/replies/${replyId}`);
    const result = response.data;
    return result;
}

async function getUserAnnouncements() {
    const response = await axios.get(
        `${base_url}/primeapi/v2/announcements?sort=-liveDate&omitDeprecated=tru`
    );
    const result = response.data;
    return result;
}

async function getMyCalendar(userId) {
    if (userId) {
        const response = await axios.get(
            // `${base_url}/users/${userId}/calendar?include=course.instances.learningObject&filter.allSessions=false`
            `${base_url}/users/${userId}/calendar?include=course%2CcontainerLO%2Ccourse.enrollment%2Ccourse.instances%2Croom&filter.allSessions=true`
        );
        const result = response?.data;
        return result;
    }
}

async function certificationQuaterlyDataCurrentQuerter(year = 2023, qtr = 2) {
    const response = await axios.get(
        `${application_url}/getCertificationData?year=${year}&qtr=${qtr}`
    );

    let result = response.data;
    return result;
}

async function certificationQuaterlyData() {
    const response = await axios.get(`${application_url}/getCertificationData`);

    let result = response?.data;
    return result;
}
async function getEnablementTeamData() {
    const response = await axios.get(`${application_url}/getEnablementData`);
    let result = response?.data;
    return result;
}

async function getUserRoleEnrollmentPresignedUrl() {
    const response = await axios.get(`${application_url}/userRoleEnrollmentPresignedUrl`);
    return response;
}

async function getCertificationPresignedUrl(year, qtr) {
    const response = await axios.get(
        `${application_url}/walloffamepresignedurl?year=${year}&qtr=${qtr}`
    );
    return response;
}

async function getCatalogPresignedUrl() {
    const response = await axios.get(`${application_url}/catalogpresignedurl`);
    return response;
}

async function getNavigationPresignedUrl() {
    const response = await axios.get(`${application_url}/navigationPrsgnUrl`);
    return response;
}

async function getCustomUserPresignedUrl() {
    const response = await axios.get(`${application_url}/customUserPrsgndUrl`);
    return response;
}

async function getSioEnablementPresignedUrl() {
    const response = await axios.get(`${application_url}/enblmntPrsgndUrl`);
    return response;
}

async function updateCSVCertificationData(url, file) {
    const config = {
        method: "PUT",
        headers: { "Content-Type": "text/csv" },
        body: file,
        redirect: "follow"
    };
    fetch(url, config);
    // const response = await axios.put(url, data )
    // return response;
}

async function updateVPEmailId(vPEmails, field, vpUserGroupId) {
    const response = await axios.post(
        `${application_url}/updateVpEmail?vPEmail=${vPEmails}&fieldValue=${field}&vpUserGroupId=${vpUserGroupId}`
    );
    return response.data;
}

async function getVPEmailId() {
    const response = await axios.get(`${application_url}/getVpEmailId`);
    let result = response.data;
    return result;
}

async function getMasterSkillPresignedUrl() {
    const response = await axios.get(`${application_url}/masterSkillsPrsgndUrl`);
    return response;
}
async function getTagData() {
    const response = await axios.get(`${base_url}/data?filter.tagName=true`);
    const result = response.data;
    return result;
}

async function getRoleWiseSkillsData(roleName, skill) {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    let url = `${application_url}/getRoleWiseSkillsData/?roleName=${roleName}`;
    if (skill) {
        url += `&skill=${skill}`;
    }
    const response = await axios.get(url, config);
    return response;
}

async function getLearnersFromManager() {
    const response = await axios.get(`${application_url}/getLearnersFromManager`);
    let result = response?.data ?? [];
    return result;
}

async function assignSkillToUser(data) {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const response = await axios.post(`${application_url}/assignSkillToUser`, data, config);
    return response;
}

async function assignCourseToLearners(data) {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const response = await axios.post(`${application_url}/assignCourseToLearners`, data, config);
    return response;
}

async function getMySkillJourney() {
    const response = await axios.get(`${application_url}/getMySkillJourney`);
    return response;
}
async function getViewSkillJourney(userId) {
    const response = await axios.get(`${application_url}/getMySkillJourney?uid=${userId}`);
    return response;
}

async function assignRollToUser(data) {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const response = await axios.post(`${application_url}/assignRoleToUsers`, data, config);
    const result = response.data;
    return result;
}

async function submitSkillForApproval(data) {
    const response = await axios.post(`${application_url}/submitForApproval`, data);
    const result = response.data;
    return result;
}

async function updatSIOEnablementTeam(data) {
    const response = await axios.post(`${application_url}/updateSIOEnablementStaticData`, data);
    const result = response.data;
    return result;
}

async function getSIOEnablementStaticData() {
    const response = await axios.get(`${application_url}/getEnablementStaticData`);
    return response;
}

async function updateEmailTemplate(type, subject, message, title) {
    const data = {
        type: type,
        subject: subject,
        message: message,
        title: title
    };

    const response = await axios.post(`${application_url}/updateEmailTemplatedata`, data);
    const result = response.data;
    return result;
}

async function getEmailTemaplateData() {
    const response = await axios.get(`${application_url}/getEmailTemplateData`);
    return response;
}

async function getLoginUserDetails() {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    // const response = await axios.get(`${application_url}/loginUser`,config);
    const response = await axios.get(`${application_url}/login`, config);
    let result = response.data;
    return result;
}

async function getMyTeamSkillsSubmissionList() {
    const response = await axios.get(`${application_url}/getSkillTransactions`);
    return response;
}

async function getChangeStatusOfSkills(data) {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const response = await axios.post(`${application_url}/changeStatusOfSkills`, data, config);
    const result = response.data;
    return result;
}

async function getApprovedRolesAndSkills() {
    const response = await axios.get(`${application_url}/getApprovalAndRoles`);
    const result = response.data;
    return result;
}

async function getPresignedUrlToDownloadCsv(id) {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const response = await axios.get(`${application_url}/csvDownloadPresgnUrl?id=${id}`, config);
    const result = response.data;
    return result;
}

async function getStatusOfLastImportCsv() {
    const response = await axios.get(`${application_url}/getStatusOfUploadCsv`);
    const result = response.data;
    return result;
}

async function getUserRoleUnEnrollmentPresignedUrl() {
    const response = await axios.get(`${application_url}/userRoleUnEnrollmentPresignedUrl`);
    return response;
}

async function getNavigationCsvUploadsTest() {
    const response = await axios.get(`${application_url}/navigationPrsgnUrlTest`);
    return response;
}

async function getRolesAndSkills() {
    const response = await axios.get(
        `${application_url}/getRolesAndSkills?roles=1&skills=1&catalogs=1`
    );
    const result = response.data;
    return result;
}

const getRolesAndSkillsDropDown = createAsyncThunk("myTeam/getRolesAndSkillsDropDown", async () => {
    const response = await axios.get(
        `${application_url}/getRolesAndSkills?roles=1&skills=1&page=skillDashboard`
    );
    return response?.data;
});

const getMyTeamStats = createAsyncThunk("myTeam/getMyTeamStats", async () => {
    const response = await axios.get(`${application_url}/getMyTeamStats`);
    return response?.data;
});

async function getSearchData(roles, skills) {
    const response = await axios.get(
        `${application_url}/getRolesAndSkills?roles=${roles}&skills=${skills}`
    );
    const result = response.data;
    return result;
}

const getFilteredDataOnApply = createAsyncThunk(
    "myTeam/getFilteredDataOnApply",
    async ({ type, value }) => {
        const response = await axios.get(
            `${application_url}/getMangerUsersRoleAndSkillData?type=${type}&values=${value}`
        );
        return response?.data;
    }
);

async function getCheckUserRoleSkills(data) {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const response = await axios.post(`${application_url}/checkUserRoleSkills`, data, config);
    const result = response.data;
    return result;
}
async function validateUserRoleSkill(data) {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const response = await axios.post(`${application_url}/validateAssign`, data, config);
    const result = response.data;
    return result;
}

async function getUpdateProfile(BodyData, userDetailId) {
    const response = await axios.patch(`${base_url}/users/${userDetailId}`, BodyData);
    const result = response?.data;
    return result;
}

async function getUploadInfo() {
    try {
        const response = await axios.get(
            "https://learningmanager.adobe.com/primeapi/v2/uploadInfo",
            {
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: "oauth " + checkToken()
                }
            }
        );

        return response;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

async function getSignerInfo() {
    const response = await axios.get(
        `${signin_url}/primeapi/v2/uploadSigner?to_sign=AWS4-HMAC-SHA256%0A20200406T064234Z%0A20200406%2Fus-east-1%2Fs3%2Faws4_request%0A8025d175eb599528e0a724c0cd820519b8723351ce7cdafa9b9011a1614c775a&datetime=20200406T064234Z`,
        {
            headers: {
                Accept: "application/vnd.api+json",
                Authorization: "oauth " + checkToken()
            }
        }
    );
    const result = response;
    return result;
}

async function uploadImage(body) {
    const response = await axios.post(`${base_url}/avatar`, {
        imageUrl: body
    });
    const result = response;
    return result;
}

async function uploadFile( payload, loId, loResourceId ) {
    const response = await axios.patch(`${base_url}/learningObjects/${loId}/loResources/${loResourceId}`,  payload );
    const result = response;
    return result;
}


async function uploadAvatarUrlImage(req) {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const response = await axios.post(`${application_url}/updateUserAvatarUrl`, req, config);
    const result = response.data;
    return result;
}

const getLearnersData = createAsyncThunk("learnerList/getLearnersData", async () => {
    const response = await axios.get(`${application_url}/getLearnerwiseData`);
    return response;
});

const getCourseData = createAsyncThunk("learnerList/getCoursewiseData", async () => {
    const response = await axios.get(`${application_url}/getCoursewiseData`);
    return response;
});

async function getNotifications() {
    const token = localStorage.getItem("access_token");
    const response = await axios.get(`${application_url}/getNotifications`, {
        headers: {
            Accept: "application/vnd.api+json",
            Authorization: "oauth " + token
        }
    });
    const result = response?.data;
    return result;
}

async function markReadNotifications(postData) {
    const token = localStorage.getItem("access_token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const response = await axios.post(`${application_url}/markReadNotifications`, postData, config);
    const result = response?.data;
    return result;
}

async function getNavigationDataNew() {
    const response = await axios.get(`${application_url}/getNavigationDataNew`);
    const result = response?.data;
    return result;
}

async function getGuiId(fromDate,toDate) {
    const response = await axios.post(`${application_url}/guidGen`,{startDate:fromDate,endDate:toDate});
    return response;
}

async function getSubmissionReports(guid) {
    const response = await axios.get(`${application_url}/fetchReportUrl?guid=${guid}`);
    const result = response?.data;
    return result;
}

export const apis = {
    getToken,
    checkToken,
    refreshToken,
    getEnrollments,
    getLearningObjects,
    retrieveL1FeedbackFormInformation,
    sendL1FeedbackAnswersFromALearner,
    getLearningObject,
    getLearningObjectsByIds,
    removeRheLearningObjectFromTheBookmarks,
    addTheLearningObjectToTheBookmarks,
    getCatalogs,
    setEnrollment,
    UpdateRrating,
    unEnroll,
    getRecommendations,
    // getUsersForAdminSide,
    getUsers,
    getUser,
    updateUser,
    getUserGroups,
    getAccount,
    getUserSkills,
    getUserSkillInterests,
    addUserSkillInterests,
    deleteUserSkillInterests,
    getUserNotifications,
    patchUserNotifications,
    getSkills,
    getBadgesForOrganization,
    getBadges,
    getBadge,
    RetrievesTheDetailsOfAUserBadge,
    RetrievesTags,
    RetrievesSkillName,

    // <---search--->
    searchCourses,
    //<---social --->
    getSocialProfileOfTheUser,
    followAUser,
    unFollowAUser,
    getFollowers,
    getFollowed,
    createBoard,
    getBoards,
    retrievesTheDetailsOfABoard,
    deleteBoard,
    getModerators,
    addModerators,
    reportAbuseForABoard,
    getBoardUsers,
    addUsersToThePrivateBoard,
    setFavoriteABoard,
    removeFavoriteABoard,
    getPostsForAGivenBoard,
    addAPostInTheBoard,
    IncrementCountOfViewsOnTheBoard,
    getPostsInTheAccount,
    deleteAPost,
    updateTheCreatedPost,
    updateOptionSelectedForTheGivenPollPost,
    reportAbuseForAPost,
    voteToThePost,
    deleteAVoteInThePost,

    //<---comments--->
    retrievesAListOfCommentsForAGivenPost,
    addCommentInThePostSpecifiedByThePostId,
    deleteAComment,
    getComment,
    voteTheGivenComment,
    deleteTheVoteMadeOnTheComment,
    updateAComment,
    reportAbuseOnTheGivenComment,

    //<---Reply--->
    addReplyToTheComment,
    getRepliesForAComment,
    getReply,
    updateAReply,
    reportAbuseOnTheReply,
    voteToTheReply,
    deleteAVoteOnTheReply,
    DeleteAReply,
    getUserAnnouncements,
    getMyCalendar,
    getMyLearningByDueDate,
    getEnrollmentCount,
    certificationQuaterlyData,
    certificationQuaterlyDataCurrentQuerter,
    getEnablementTeamData,
    getCertificationPresignedUrl,
    updateCSVCertificationData,
    getCatalogPresignedUrl,
    getNavigationPresignedUrl,
    getSioEnablementPresignedUrl,
    getLearningObjectsByCatlogID,
    getLevel,
    updateVPEmailId,
    getVPEmailId,
    getMasterSkillPresignedUrl,
    getRoleWiseSkillsData,
    getTagData,
    assignSkillToUser,
    assignCourseToLearners,
    assignRollToUser,
    getLearnersFromManager,
    getMySkillJourney,
    submitSkillForApproval,
    getLearningCoursesbySkillName,
    // SIO EnablementTeam
    updatSIOEnablementTeam,
    getSIOEnablementStaticData,
    //Email Template
    updateEmailTemplate,
    getEmailTemaplateData,
    getLoginUserDetails,
    getMyTeamSkillsSubmissionList,
    getChangeStatusOfSkills,
    getCustomUserPresignedUrl,
    getUserRoleEnrollmentPresignedUrl,
    getApprovedRolesAndSkills,
    getPresignedUrlToDownloadCsv,
    getRolesAndSkills,
    getSearchData,
    getMyTeamStats,
    getFilteredDataOnApply,
    //status of last import csv
    getStatusOfLastImportCsv,
    getUserRoleUnEnrollmentPresignedUrl,

    getCheckUserRoleSkills,
    getUpdateProfile,
    getViewSkillJourney,
    getUploadInfo,
    getSignerInfo,
    uploadImage,
    uploadFile,
    uploadAvatarUrlImage,
    getLearnersData,
    getCourseData,
    getNotifications,
    markReadNotifications,
    getNavigationCsvUploadsTest,
    getNavigationDataNew,
    validateUserRoleSkill,
    getRolesAndSkillsDropDown,
    getSubmissionReports,
    getGuiId
};
