/* eslint-disable indent */
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RejectInfoIcon from "./RejectInfoIcon";
import { apis } from "../../services/Api";
import Modal from "react-bootstrap/Modal";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";
import Failure_Icon from "../../assets/images/Failure_Icon.png";
import moment from "moment";
import CheckBoxTooltip from "../CheckBoxTooltip";
import ApprovedInfoIcon from "./ApprovedInfoIcon";
import LevelInfoIcon from "./LevelInfoIcon";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setApproveRejectData, setCollapseData } from "../../slices/mainSlice";
import "./CustomAccordion.css";

function CustomSkillRow({
    index,
    content,
    i,
    handleSkillSelect,
    showActionColumn,
    setAddress,
    address,
    setChekedLernerIds,
    chekedLernerIds,
    contentTransectionId,
    getMySkillJourneyData,
    // handleClearAll,
    selectedSkills,
    contentSkillId,
    handleViewSelectSkill,
    updateFilterUi
    
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const reduxDispatch = useDispatch();
    const { collapseData } = useSelector((state) => state.main);

    const [dataContent, setDataContent] = useState([]);
    const [dataForReject, setdataForReject] = useState("");
    const [dataForApprove, setDataForApprove] = useState("");
    const [displayMessageBox, setDisplayMessageBox] = useState(false);
    const [displayMessageBoxForApproval, setDisplayMessageBoxForApproval] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [rejectPopError, setRejectPopError] = useState("");
    const [messageForFailure, setMessageForFailure] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipText, setTooltipText] = useState("");

    const crumbs = [
        { label: "Home", url: "/" },
        { label: "My Skill Journey", url: location }
    ];

    useEffect(() => {
        const tData = [...content.history];

        if ([...collapseData].some((item) => JSON.stringify(item) === JSON.stringify(content))) {
            setDataContent([...tData]);
        } else {
            setDataContent([tData[0]]);
        }
    }, [content]);

    const styleStatus = (status) => {
        if (status === "Rejected") {
            return "rejected";
        } else if (status === "In Progress") {
            return "in-progress";
        } else if (status === "Approved") {
            return "approved";
        } else if (status === "Not Started") {
            return "not-started";
        } else if (status === "Awaiting Approval") {
            return "waiting-approval";
        }
    };

    const navigateTo = (skillName, SkillGroupName, SkillDescription, RoleName, hist) => {
        let skillNameandDescription = [skillName, SkillDescription, SkillGroupName, RoleName, hist];
        sessionStorage.setItem("settingSkillNameandDescription", JSON.stringify(skillNameandDescription));
        navigate("/roleskilljourney/viewcontent", { state: { crumbs, viewContent: true } });
    };

    const handleClick = () => {
        const data = [...collapseData];

        const isObjectPresent = data.some(
            (item) => JSON.stringify(item) === JSON.stringify(content)
        );

        let updatedArray;
        if (isObjectPresent) {
            // Remove the object from the array
            updatedArray = data.filter((item) => JSON.stringify(item) !== JSON.stringify(content));
        } else {
            // Add the object to the array
            updatedArray = [...data, content];
        }

        reduxDispatch(setCollapseData(updatedArray));
    };

    const handleCheckboxMouseEnter = (content, first) => {
        const contentHistory = content.history[0];
        const contentDisabled = content.disabled;
        if (first) {
            setShowTooltip(!showTooltip);
            setTooltipText("This skill is not yet submitted for approval by the learner.");
        } else {
            const disable =
                contentHistory.status?.toLowerCase() === "awaiting approval" ||
                contentHistory.CurrentLevel === 300 ||
                contentDisabled
                    ? true
                    : false;
            setShowTooltip(disable);

            let text;
            if (contentHistory.status?.toLowerCase() === "awaiting approval" || contentDisabled) {
                text =
                    // eslint-disable-next-line quotes
                    'This skill is in "Awaiting Approval" status and cannot be selected for submission.';
            } else if (contentHistory.CurrentLevel === 300) {
                text =
                    "This skill is already at the highest proficiency level (300) and cannot be submitted for approval.";
            }
            setTooltipText(text);
        }
    };

    const handleCheckboxMouseLeave = () => {
        setShowTooltip(false);
    };

    const handleSubmitDisplayMessage = async () => {
        if (address.trim() !== "") {
            let payload = {
                skilltransactiondata:
                    [
                        {
                            skillTransactionId: dataForReject.TransactionId,
                            ApprovalStatus: "Rejected",
                            rejectReason: address
                        }
                    ] ?? []
            };
            reduxDispatch(setApproveRejectData([dataForReject.TransactionId]))
            const response = await apis.getChangeStatusOfSkills(payload);
            setDisplayMessageBox(false);
            setAddress("");
            setRejectPopError("");
            // toast.success("Selected Skills are Rejected successfully.");
            if (response.message === "all_rejected") {
                setMessageforPopup("Selected Skill(s) have been rejected successfully.");
                setShowModal(true);
            } else if (response.message === "partially_rejected") {
                const skillName = [];
                response?.extraInfo?.notUpdatedSkills?.forEach((a) => {
                    skillName?.push(a?.SkillName);
                });
                setMessageforPopup(
                    `Few Skill(s) have been rejected successfully. Unable to reject following Skill(s): ${skillName.join(
                        ", "
                    )}`
                );
                setShowModal(true);
            } else if (response.message === "none_rejected") {
                const link = (
                    <>
                        Unable to complete the request, please retry after some time. If you need
                        further help, contact{" "}
                        <a
                            href="mailto:dx-sio-enablement@adobe.com"
                            className="btn btn-link btn-view fontSize14Text filterEffect">
                            dx-sio-enablement@adobe.com
                        </a>
                        .
                    </>
                );
                setMessageForFailure(link);
                setShowModal(true);
            }

            const updatedChecks = chekedLernerIds.filter(
                (ele) => ele !== dataForReject?.TransactionId
            );
            setChekedLernerIds([...updatedChecks]);
            setDataContent();
        } else {
            setRejectPopError("Please enter a reason for rejection.");
        }
    };

    const handleOkDisplayMessage = async () => {
        setShowLoader(true);
        let payload = {
            skilltransactiondata:
                [
                    { skillTransactionId: dataForApprove.TransactionId, ApprovalStatus: "Approved" }
                ] ?? []
        };
        reduxDispatch(setApproveRejectData([dataForApprove.TransactionId]))
        const response = await apis.getChangeStatusOfSkills(payload);
        setDisplayMessageBoxForApproval(false);
        setShowLoader(false);
        if (response.message === "all_approved") {
            setMessageforPopup("Selected Skill(s) have been approved successfully.");
            setShowModal(true);
        } else if (response.message === "partially_approved") {
            const skillName = [];
            response?.extraInfo?.notUpdatedSkills?.forEach((a) => {
                skillName?.push(a?.SkillName);
            });
            setMessageforPopup(
                `Few Skill(s) have been approved successfully. Unable to approve following Skill(s): ${skillName.join(
                    ", "
                )}`
            );
            setShowModal(true);
        } else if (response.message === "none_approved") {
            const link = (
                <>
                    Unable to complete the request, please retry after some time. If you need
                    further help, contact{" "}
                    <a
                        href="mailto:dx-sio-enablement@adobe.com"
                        className="btn btn-link btn-view fontSize14Text filterEffect">
                        dx-sio-enablement@adobe.com
                    </a>
                    .
                </>
            );
            setMessageForFailure(link);
            setShowModal(true);
        }

        setDataContent();
        const updatedChecks = chekedLernerIds.filter((ele) => ele !== dataForApprove.TransactionId);
        setChekedLernerIds([...updatedChecks]);
    };

    const handleClickCancel = (data) => {
        setdataForReject(data);
        setDisplayMessageBox(true);
    };

    const handleClickApprove = async (hist) => {
        setDataForApprove(hist);
        setDisplayMessageBoxForApproval(true);
    };

    const handleCloseDisplayMessage = () => {
        setAddress("");
        setRejectPopError("");
        setDisplayMessageBox(false);
        setDisplayMessageBoxForApproval(false);
    };

    const handleInputChange = (event) => {
        setAddress(event.target.value);
        setRejectPopError("");
    };

    const handleChange = (e) => {
        const checkedValue = e?.target?.checked;
        handleSkillSelect(content.history[0], checkedValue, contentSkillId, index);
    };

    const handleChange1 = (e) => {
        const checkedValue = e.target.checked;
        handleViewSelectSkill(content.history[0], checkedValue, contentTransectionId, index);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        // handleClearAll();
        updateFilterUi();
        getMySkillJourneyData(true);
        
    };

    return (
        <tr>
            <td className="container-tbl-tr-btn">
                <div className="custom-accordion-item-action">
                    {content.history.length > 1 && (
                        <button className="btn-action" onClick={handleClick}>
                            {[...collapseData].some(
                                (item) => JSON.stringify(item) === JSON.stringify(content)
                            ) ? (
                                <div className="arrow up"></div>
                            ) : (
                                <div className="arrow down"></div>
                            )}
                        </button>
                    )}
                </div>
            </td>
            {showActionColumn ? (
                <td className="container-tbl-tr-btn">
                    {content && content.history[0]?.status === "Awaiting Approval" ? (
                        <Form.Check
                            type="checkbox"
                            id={i}
                            label=""
                            value={styleStatus(content.Status)}
                            className="check-box checkboxOne"
                            onChange={(e) => handleChange1(e)}
                            checked={chekedLernerIds.includes(content.history[0]?.TransactionId)}
                        />
                    ) : (
                        <div
                            onMouseEnter={() => handleCheckboxMouseEnter(content, "first")}
                            onMouseLeave={() => handleCheckboxMouseLeave()}>
                            <Form.Check
                                type="checkbox"
                                disabled
                                id={i}
                                label=""
                                value={styleStatus(content.Status)}
                                className="check-box checkboxOne custom-tooltip-checkbox"
                            />
                            {showTooltip && <CheckBoxTooltip index={i} message={tooltipText} />}
                        </div>
                    )}
                </td>
            ) : (
                <td key={`${i}+checkbox`} className="container-tbl-tr-btn">
                    <div
                        onMouseEnter={() => handleCheckboxMouseEnter(content)}
                        onMouseLeave={() => handleCheckboxMouseLeave()}>
                        <Form.Check
                            type="checkbox"
                            id={content.history[0]?.skillIdTargetLevel}
                            label=""
                            value={styleStatus(content.history[0]?.status)}
                            className="check-box checkboxOne custom-tooltip-checkbox"
                            onChange={(e) => handleChange(e)}
                            disabled={
                                content.history[0]?.status?.toLowerCase() === "awaiting approval" ||
                                content.history[0]?.CurrentLevel === 300 ||
                                content?.disabled
                            }
                            checked={selectedSkills?.includes(
                                content.history[0]?.skillIdTargetLevel
                            )}
                        />
                        {showTooltip && <CheckBoxTooltip index={i} message={tooltipText} />}
                    </div>
                </td>
            )}
            <td className="container-tbl-tr-tbl">
                <table className="custom-accordion-tbl">
                    {dataContent?.length > 0 &&
                        dataContent?.map((hist, keyHis) => {
                            return (
                                <tr key={keyHis}>
                                    <td
                                        key={`${keyHis}+1`}
                                        className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-skillName">
                                        {showActionColumn ? (
                                            <td
                                                key={`${keyHis}+2`}
                                                className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-skillGroup">
                                                {t(hist.skillName)}
                                            </td>
                                        ) : (
                                            <Nav.Link
                                                key={`${keyHis}+Navigation`}
                                                onClick={() =>
                                                    navigateTo(
                                                        hist?.skillName,
                                                        hist?.skillGroup,
                                                        hist?.SkillDescription,
                                                        hist?.RoleName,
                                                        hist
                                                    )
                                                }>
                                                {t(hist.skillName)}
                                            </Nav.Link>
                                        )}
                                    </td>
                                    <td
                                        key={`${keyHis}+2`}
                                        className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-skillGroup">
                                        {hist.skillGroup}
                                    </td>
                                    <td
                                        key={`${keyHis}+3`}
                                        className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-currencyLevel currentLevel-tooltip">
                                        {hist?.CurrentLevel === 0 || hist?.CurrentLevel === null
                                            ? 0
                                            : hist?.CurrentLevel}
                                            {console.log("CurrentLevel",hist?.CurrentLevel)}
                                        {hist?.status === "Not Started" &&
                                        hist?.CurrentLevel &&
                                        hist?.CurrentLevel !== 0 ? (
                                            <LevelInfoIcon address={`${hist?.ApprovedFromRole}`} />
                                        ) : (
                                            ""
                                        )}
                                    </td>
                                    <td
                                        key={`${keyHis}+4`}
                                        className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-currencyLevel">
                                        {hist?.SubmittedLevel === 0 || hist?.SubmittedLevel === null
                                            ? 0
                                            : hist?.SubmittedLevel}
                                    </td>
                                    <td
                                        key={`${keyHis}+5`}
                                        className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-targetLevel">
                                        {hist?.TargetLevel === 0 || hist?.TargetLevel === null
                                            ? "-"
                                            : hist?.TargetLevel}
                                    </td>
                                    <td
                                        key={`${keyHis}+6`}
                                        className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-submitDate">
                                        {hist?.submittedDate === null
                                            ? "-"
                                            : moment(hist?.submittedDate).format("MMM Do YYYY")}
                                    </td>
                                    <td
                                        key={`${keyHis}+7`}
                                        className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-approvalDate">
                                        {hist?.aprroveDate === null
                                            ? "-"
                                            : moment(hist?.aprroveDate).format("MMM Do YYYY")}
                                    </td>
                                    <td
                                        key={`${keyHis}+8`}
                                        className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-status">
                                        <div className="tbl-info-container">
                                            <p
                                                className={`tbl-btn-status ${styleStatus(
                                                    hist.status
                                                )}`}>
                                                {hist.status === "Approved" ? (
                                                    <>
                                                        <span>{hist.status}</span>
                                                        {hist.showApproveIcon ? (
                                                            <ApprovedInfoIcon address="Approved by Admin" />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                ) : hist.status !== "Rejected" ? (
                                                    <span>{hist.status}</span>
                                                ) : (
                                                    <>
                                                        <span>{hist.status}</span>
                                                        <RejectInfoIcon address={hist.keynote} />
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </td>
                                    {showActionColumn && (
                                        <td className="container-tbl-tr-tbl-tr container-tbl-tr-tbl-tr-hasActionBtns hasActionBtnsContainer">
                                            {hist?.status == "Awaiting Approval" ? (
                                                <>
                                                    <button
                                                        className="hasAction hasActionCancel"
                                                        onClick={() => handleClickCancel(hist)}>
                                                        <i className="pi pi-times"></i>
                                                    </button>
                                                    <button
                                                        className="hasAction hasActionCorrect"
                                                        onClick={() => handleClickApprove(hist)}>
                                                        <i className="pi pi-check"></i>
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        className="hasAction hasActionCancel hidden-visibility"
                                                        onClick={() => handleClickCancel(hist)}>
                                                        <i className="pi pi-times"></i>
                                                    </button>
                                                    <button
                                                        className="hasAction hasActionCorrect hidden-visibility"
                                                        onClick={() => handleClickApprove(hist)}>
                                                        <i className="pi pi-check"></i>
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                </table>
            </td>

            <Modal centered show={displayMessageBox} className="skill-submission-modal">
                <Modal.Body>
                    <div className="success-modal-container px-3">
                        <div className="content-section">
                            <div>
                                <Form>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>
                                            <div className="content-label">
                                                Rejection Reason <span className="astrix">*</span>
                                            </div>
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            className="content-textarea"
                                            rows="5"
                                            name="address"
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        {rejectPopError && (
                                            <span style={{ color: "red" }}>{rejectPopError}</span>
                                        )}
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-link m-2"
                        onClick={() => handleCloseDisplayMessage()}>
                        Cancel
                    </button>
                    <button
                        className="btn primary-btn primary-blue m-2 brn-submit"
                        onClick={() => handleSubmitDisplayMessage()}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>

            <div className="modal-container success-modal">
                <Modal centered show={displayMessageBoxForApproval}>
                    <Modal.Body>
                        <div className="success-modal-container my-5 text-center">
                            <img
                                className="failed-icon mx-auto"
                                src={Failure_Icon}
                                alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">
                                Are you sure you want to approve the selected Skill(s)?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-link m-2"
                            onClick={() => handleCloseDisplayMessage()}>
                            Cancel
                        </button>
                        <button
                            className="btn primary-btn primary-blue m-2"
                            onClick={() => handleOkDisplayMessage()}
                            disabled={showLoader}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>

                <ModalforSuccessAndFailure
                    messageforPopup={messageforPopup}
                    show={showModal}
                    onClose={handleCloseModal}
                    messageForFailure={messageForFailure}
                />
            </div>
        </tr>
    );
}

export default CustomSkillRow;
