/* eslint-disable indent */
import moment from "moment";

export const searchFiltered = (data, searchTerm, pageName = null) => {
    if (["learnerDetails", "courseDetails"].includes(pageName)) {
        return data.filter((item) => {
            return Object?.entries(item)?.some(([key, value]) => {
                const stringValue = value?.toString()?.toLowerCase();
                const searchValue = searchTerm?.toLowerCase();

                if (key === "Progress") {
                    const progressValue = stringValue.replace("%", "");
                    if (progressValue.includes(searchValue)) {
                        return true;
                    } else if (
                        searchValue.endsWith("%") &&
                        progressValue === searchValue.substring(0, searchValue.length - 1)
                    ) {
                        return true;
                    }
                    return false;
                }

                if (key === "EnrollmentDate" || key === "DueDate" || key === "CompletionDate") {
                    const formattedDate = moment(value).format("MMM Do YYYY");
                    return formattedDate.toLowerCase().includes(searchValue);
                }

                if (!isNaN(value) && stringValue?.includes(searchValue)) {
                    return true;
                }

                if (typeof value === "string" && stringValue?.includes(searchValue)) {
                    return true;
                }

                return false;
            });
        });
    } else {
        return data?.filter((item) => {
            return Object?.entries(item)?.some(([key, value]) => {
                const stringValue = value?.toString()?.toLowerCase();
                const searchValue = searchTerm?.toLowerCase();

                if (pageName === "learnerReport" && key === "courseName") {
                    return false;
                }

                if (
                    [
                        "Enrolled",
                        "Completed",
                        "InProgress",
                        "NotStarted",
                        "EnrolledLearnersList",
                        "CompletedLearners",
                        "InProgressLearners",
                        "NotStartedLearners"
                    ].includes(key)
                ) {
                    return false;
                }

                if (key === "Progress") {
                    let progressValue = stringValue.replace("%", "");
                    const parsedProgressValue = parseFloat(progressValue);

                    if (!isNaN(parsedProgressValue)) {
                        if (Math.abs(parsedProgressValue - parseFloat(searchValue)) < 0.01) {
                            return true;
                        }
                        if (progressValue.toString().includes(searchValue)) {
                            return true;
                        }
                    }

                    return false;
                }

                if (key === "EnrollmentDate" || key === "DueDate" || key === "CompletionDate") {
                    const formattedDate = moment(value).format("MMM Do YYYY");
                    return formattedDate.toLowerCase().includes(searchValue);
                }

                return (
                    key &&
                    (!isNaN(value) || typeof value === "string") &&
                    stringValue?.includes(searchValue)
                );
            });
        });
    }
};

export const searchInArray = (data, searchInput) => {
    const excludedKeys = [
        "TransactionId",
        "groupId",
        "keynote",
        "roleId",
        "roleSkillGroupId",
        "skillId",
        "skillIdTargetLevel",
        "userRoleSkillsId",
        "SkillDescription"
    ];

    const filteredResults = (data || []).flatMap((innerArray) =>
        (innerArray || []).flatMap(({ id, title, skills }) => {
            const filteredSkills = (skills || [])
                .map(({ skillName, history }) => {
                    const filteredHistory = (history || []).filter(({ ...rest }) => {
                        return Object.entries(rest).some(([key, value]) => {
                            if (excludedKeys.includes(key)) {
                                return false;
                            }

                            const stringValue = value?.toString()?.toLowerCase();
                            const searchValue = searchInput?.toLowerCase();

                            if (!isNaN(value) && stringValue?.includes(searchValue)) {
                                return true;
                            }

                            if (
                                (key === "submittedDate" || key === "aprroveDate") &&
                                moment(value)
                                    .format("MMM Do YYYY")
                                    .toLowerCase()
                                    .includes(searchValue)
                            ) {
                                return true;
                            }

                            if (typeof value === "string" && stringValue?.includes(searchValue)) {
                                return true;
                            }

                            return false;
                        });
                    });

                    return filteredHistory.length > 0
                        ? { skillName, history: filteredHistory }
                        : null;
                })
                .filter(Boolean);

            return filteredSkills.length > 0 ? [{ id, title, skills: filteredSkills }] : [];
        })
    );

    return filteredResults;
};

export const skillSubmissionSearch = (data, searchInput) => {
    const filteredResults = [];

    data?.forEach((item) => {
        const filteredItem = {
            skills: []
        };

        item?.skills?.forEach((skill) => {
            const skillName = skill?.skillName;
            const Skills = skill?.Skills;
            const UserRoleSkillId = skill?.UserRoleSkillId;
            const lernerId = skill?.lernerId;
            const transactionId = skill?.transactionId;
            const filteredHistory = skill?.history?.filter((historyItem) => {
                return Object.entries(historyItem.item).some(([key, value]) => {
                    if (
                        ["transactionId", "UserRoleSkillId", "RejectReason", "UserId"].includes(key)
                    ) {
                        return false;
                    }

                    const stringValue = value?.toString()?.toLowerCase();
                    const searchValue = searchInput?.toLowerCase();

                    if (key === "SubmittedDateTime" || key === "ApprovalOrRejectedDate") {
                        const formattedDate = moment(value).format("MMM Do YYYY");
                        return formattedDate.toLowerCase().includes(searchValue);
                    }

                    if (!isNaN(value) && stringValue?.includes(searchValue)) {
                        return true;
                    }

                    if (typeof value === "string" && stringValue?.includes(searchValue)) {
                        return true;
                    }

                    return false;
                });
            });

            if (filteredHistory.length > 0) {
                filteredItem.skills.push({
                    skillName: skillName,
                    Skills: Skills,
                    UserRoleSkillId: UserRoleSkillId,
                    lernerId: lernerId,
                    transactionId: transactionId,
                    history: filteredHistory.map((historyItem) => historyItem)
                });
            }
        });

        if (filteredItem.skills.length > 0) {
            filteredResults.push(filteredItem);
        }
    });
    // const excludedKeys = ["transactionId", "UserRoleSkillId", "RejectReason", "UserId"];

    // const filteredResults = data
    //     ?.map((item) => {
    //         const filteredSkills = item?.skills?.filter((skill) => {
    //             const { skillName, Skills, UserRoleSkillId, lernerId, transactionId, history } =
    //                 skill;

    //             const filteredHistory = history?.filter((historyItem) => {
    //                 return (
    //                     !excludedKeys.includes(Object.keys(historyItem.item)) &&
    //                     Object.values(historyItem.item).some((value) => {
    //                         const stringValue = value?.toString()?.toLowerCase();
    //                         const searchValue = searchInput?.toLowerCase();

    //                         if (
    //                             ["SubmittedDateTime", "ApprovalOrRejectedDate"].includes(
    //                                 historyItem.key
    //                             )
    //                         ) {
    //                             const formattedDate = moment(value)
    //                                 .format("MMM Do YYYY")
    //                                 .toLowerCase();
    //                             return formattedDate.includes(searchValue);
    //                         }

    //                         return (
    //                             (!isNaN(value) || typeof value === "string") &&
    //                             stringValue?.includes(searchValue)
    //                         );
    //                     })
    //                 );
    //             });

    //             return filteredHistory.length > 0
    //                 ? {
    //                       skillName,
    //                       Skills,
    //                       UserRoleSkillId,
    //                       lernerId,
    //                       transactionId,
    //                       history: filteredHistory
    //                   }
    //                 : null;
    //         });

    //         const filteredItem = {
    //             skills: filteredSkills.filter((skill) => skill !== null)
    //         };

    //         return filteredItem.skills.length > 0 ? filteredItem : null;
    //     })
    //     .filter((item) => item !== null);

    return filteredResults;
};
