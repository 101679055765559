import Container from "react-bootstrap/Container";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TableHeading from "../../component/TableHeading";
import LearnerReportsCellContent from "./LearnerReportsCellContent";
import Breadcrumbs from "../../common/Breadcrumbs";
import { apis } from "../../services/Api";
import Loader from "../../common/Loader/Loader";
import Scroller from "../../common/Scroller/Scroller";
import { _tableHeaderLearnerReports } from "../../data/dataLearnerReports";
import { learnerReportsBreadCrumbs } from "../../constant/breadCrumbs";
import DataTable from "../../component/DataTable";
import useDataReports from "../../hooks/useDataReports";
import "./LearnerReports.css";

function LearnerReports() {
    const dispatch = useDispatch();
    const { isPending, learnerReportList } = useSelector((state) => state.learnerList);

    const {
        searchTerm,
        filteredData,
        currentItems,
        currentPage,
        itemsPerPage,
        sortKey,
        sortOrder,
        handleSort,
        handleInputChange,
        setCurrentPage
    } = useDataReports({ data: learnerReportList, pageName: "learnerReport" });

    useEffect(() => {
        dispatch(apis.getLearnersData());
    }, [dispatch]);

    return (
        <>
            <Breadcrumbs crumbs={learnerReportsBreadCrumbs} />
            <Container fluid className="pl-30 py-4">
                <div className="headerImage">
                    <h1>Learner Report</h1>
                </div>
            </Container>

            {!isPending ? (
                <Container fluid className="pl-30 py-4">
                    <div className="learnerReports">
                        <div className="learnerReportsTable">
                            <DataTable
                                handleInputChange={handleInputChange}
                                searchTerm={searchTerm}
                                currentItems={currentItems}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                filteredData={filteredData}
                                itemsPerPage={itemsPerPage}>
                                <TableHeading
                                    sortKey={sortKey}
                                    sortOrder={sortOrder}
                                    columns={_tableHeaderLearnerReports}
                                    handleSort={handleSort}
                                />
                                <LearnerReportsCellContent data={currentItems} />
                            </DataTable>
                        </div>
                    </div>
                </Container>
            ) : (
                <Loader />
            )}
            <Scroller />
        </>
    );
}

export default LearnerReports;
