import { useState, useEffect } from "react";
import { debounce, orderBy } from "lodash";
import { searchFiltered } from "../utils/dataTable";

// Custom hook for managing the reports data and filtering
const useDataReports = ({ data, pageName = "" }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [sortKey, setSortKey] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    // const [filteredData, setFilteredData] = useState([]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);

    const handleSort = (key) => {
        if (key === sortKey) {
            const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
            setSortOrder(newSortOrder);
        } else {
            setSortKey(key);
            setSortOrder("asc");
        }
        setCurrentPage(1);
    };

    const handleInputChange = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        debouncedSearch();
    };

    const debouncedSearch = debounce(() => {
        setCurrentPage(1);
    }, 500);

    // const handlerFilteredData = () => {
    //     const filtered = searchFiltered(filteredData, searchTerm);
    //     setFilteredData(filtered);
    // };

    const filteredData = searchFiltered(data, searchTerm, pageName);
    const sortedData = orderBy(
        filteredData,
        [
            (row) => {
                if (sortKey === "Progress") {
                    return parseFloat(row[sortKey].replace("%", ""));
                } else {
                    // Default sorting logic for other columns
                    return row[sortKey];
                }
            }
        ],
        [sortOrder]
    );

    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    // useEffect(() => {
    //     if (!searchTerm) {
    //         setFilteredData(data);
    //     }
    // }, [data, searchTerm]);

    return {
        searchTerm,
        filteredData,
        currentItems,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        sortKey,
        sortOrder,
        handleSort,
        handleInputChange
    };
};

export default useDataReports;
